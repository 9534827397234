import React, { useEffect, useState } from 'react';

import Landing from './landing';
import LandingGreen from './landinggreen';
import Pricing from './pricing';

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    // fetch('/api/train')
    //   .then((response) => response.text())
    //   .then((data) => {
    //     setMessage(data);
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
  }, []);

  return (
    <main>
      <LandingGreen />
      <Pricing />
    </main>
  );
}

export default App;





