import React, { useState } from 'react';

export default function Pricing() {
  return (
    <section className="w-full py-12 bg-gradient-to-r from-gray-50 to-gray-100 dark:from-zinc-900 dark:to-zinc-800 flex items-center justify-center">
      <div className="container px-4 md:px-6">
        <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-3 md:gap-8">
          <div className="flex flex-col p-6 bg-white shadow-lg rounded-lg dark:bg-zinc-850 justify-between border border-gray-300">
            <div>
              <h3 className="text-2xl font-bold text-center">Basic</h3>
              <div className="mt-4 text-center text-zinc-600 dark:text-zinc-400">
                <span className="text-4xl font-bold">Free</span>
              </div>
              <ul className="mt-4 space-y-2">
                <li className="flex items-center">
                  
                  Linear Regression
                </li>
                <li className="flex items-center">
                  
                 
                </li>
                <li className="flex items-center">
                 
                  
                </li>
              </ul>
            </div>
            <div className="mt-6">
                {/* <button className="w-full">Get Started</button> */}
               
            </div>
          </div>
          <div className="relative flex flex-col p-6 bg-white shadow-lg rounded-lg dark:bg-zinc-850 justify-between border border-purple-500">
            <div className="px-3 py-1 text-sm text-white bg-gradient-to-r from-pink-500 to-purple-500 rounded-full inline-block absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              Popular
            </div>
            <div>
              <h3 className="text-2xl font-bold text-center">Pro</h3>
              <div className="mt-4 text-center text-zinc-600 dark:text-zinc-400">
                <span className="text-4xl font-bold">€20</span>/ month
              </div>
              <ul className="mt-4 space-y-2">
                <li className="flex items-center">
                  
                  Hypothesis Testing
                </li>
                <li className="flex items-center">
                  
                  Classification
                </li>
                <li className="flex items-center">
                  
                
                </li>
                <li className="flex items-center">
                  
                 
                </li>
              </ul>
            </div>
            <div className="mt-6">
              
            </div>
          </div>
          <div className="flex flex-col p-6 bg-white shadow-lg rounded-lg dark:bg-zinc-850 justify-between border border-gray-300">
            <div>
              <h3 className="text-2xl font-bold text-center">Ultra</h3>
              <div className="mt-4 text-center text-zinc-600 dark:text-zinc-400">
                <span className="text-4xl font-bold">€50</span>/ month
              </div>
              <ul className="mt-4 space-y-2">
                <li className="flex items-center">
                  
                  Publish model
                </li>
                <li className="flex items-center">
                  
                  
                </li>
                <li className="flex items-center">
                  
                 
                </li>
                <li className="flex items-center">
                  
                 
                </li>
                <li className="flex items-center">
                  
                 
                </li>
              </ul>
            </div>
            <div className="mt-6">
              
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  )
}
